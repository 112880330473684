import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import styled, { keyframes } from "styled-components";
import Web3 from "web3";
import { stateStore } from "./stateStore.js";
import Metamask from "./Metamask";
import Minting from "./Minting";
import Money from "./Money";
import CheckNFT from "./CheckNFT";
import Landing from "./Landing";
import MintingDemo from "./MintingDemo";
import CheckNFTDemo from "./CheckNFTDemo";



export default function Home() {
  const meta = stateStore.useState((s) => s.meta);
  const web3 = stateStore.useState((s) => s.web3);
  const balance = stateStore.useState((s) => s.balance);
  const priceMinting = stateStore.useState((s) => s.mintingPrice);
  const begin = stateStore.useState((s)=> s.begin);
  const metaPass = stateStore.useState((s)=> s.metaPass);
  const moneyPass = stateStore.useState((s)=> s.moneyPass);
const NFTDemo = stateStore.useState((s)=> s.NFTDemo);


  return (
    
      <s.Container>
        <s.TextTitle>VIP NFT Creator</s.TextTitle>

        { !begin ? 

        <Landing/>

        : 
<div>
        {!meta && !metaPass ? (
          //Metamask n'est pas installé
          <Metamask />
        ) : (
          <div />
        )}

        {/*  Meta est installé mais pas assez pour mint */}
        {(meta && balance < priceMinting)  ? (
          <Money />
        ) : (
          //  Meta est installé et balance suffisante pour un mint
           
          <div />

          
        )}
       {(metaPass && !moneyPass && !meta) ? (
          <Money />
        ) : (
          //  Meta est installé et balance suffisante pour un mint
           
          <div />

          
        )}



        {meta && balance > priceMinting  ? (
          <div>
            <Minting />           
          </div>
        ) : (
          <div />
        )}

           {moneyPass && !NFTDemo && !meta? (
          <div>
            <MintingDemo />           
          </div>
        ) : (
          <div />
        )}


{NFTDemo && !meta ? (
          <div>
            <CheckNFTDemo />           
          </div>
        ) : (
          <div />
        )}

</div>
      }
      </s.Container>
   
  );
}
