import { Store } from "pullstate";

export const stateStore = new Store({
  chain: "matic",
  balance : 0,
  contract : "0x136Ad81DB2b184cE9F8Af0288420B15BaB719eDA", // "0x99677BD430fC62C49fBf745a152348eF5c47b30d",//"0x006c981bf7FE03615533712a432dec0031bC1059", //rinkeby
  mintingPrice : 999,
  isNFT : false,
  NFTWalletStore : [],
  meta: null,
  userAccount: null,
  web3: null,
  maticPrice : 1,
  walletOwner : null,
  begin : false,
  metaPass : false,
  moneyPass : false,
  mintingPass : false,
  NFTDemo : false,
});
