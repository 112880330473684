import React, { useEffect, useState } from "react";
import * as s from "../styles/globalStyles";
import { stateStore } from "./stateStore.js";
import VIPNFTCreator from "../ABI/VIPNFTCreator";
import { Link } from "react-router-dom";
import rarity from "../config/rarity";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";


export default function CheckNFT() {
  const meta = stateStore.useState((s) => s.meta);
  const web3 = stateStore.useState((s) => s.web3);
  const contractAddress = stateStore.useState((s) => s.contract);
  const walletOwner = stateStore.useState((s) => s.walletOwner);
  // const [walletOwner, setWalletOwner] = useState(0);
  const [NFTDetail, setNFTDetail] = useState(0);
  const [NFTmetadata, setNFTmetadata] = useState([]);
  let NFTArrays = [];
  const handleClick = async () => {
    const contract = new web3.eth.Contract(VIPNFTCreator, contractAddress);
    const resultWalletOwner = await contract.methods
      .walletOfOwner(meta)
      .call({ from: meta });
    stateStore.update((s) => {
      s.walletOwner = resultWalletOwner;
    });

    console.log("wallet owner " + resultWalletOwner);
    console.log("wallet owner has " + resultWalletOwner.length);

    if (resultWalletOwner != 0) {
      for (let i = 0; i <= resultWalletOwner.length; i++) {
        const NFTOwned = await contract.methods
          .tokenURI(resultWalletOwner[i])
          .call({ from: meta });
        setNFTDetail(NFTOwned);
        const response = await fetch(NFTOwned);

        if (!response.ok) throw new Error(response.statusText);

        const json = await response.json();
        console.log(json.image);
        NFTArrays.push(json);
        setNFTmetadata(NFTArrays);
        console.log("NFTArrays", NFTArrays);
      }
    }
    setNFTmetadata(NFTArrays);
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <s.Screen>
      <s.Container>
        <div style={{display : "flex", flexDirection :"row"}}>
        <s.button onClick={handleClick}>Refresh</s.button>
        <Link to="/">
          <s.button><Trans i18nKey="checknft-button1" /></s.button>
        </Link>
        </div>
        <div style={{ display: "flex", flexDirection: "raw" }}>
          <s.grid>
          {NFTmetadata.map((NFTArray) => (
            // <s.cardNFT key={NFTArray.name}>
            <s.cardNFT>
              <img
                style={{ maxWidth: "90%", maxHeight: "90%", padding: "10px" }}
                src={NFTArray.image}
              />
              {NFTArray.name}
              {NFTArray.attributes[2].value == rarity.value ? (
                  <Link to="/winner">
                  <s.button  style={{width : "auto", padding : "8px"}}><Trans i18nKey="checknft-button2" /></s.button>
                  </Link>
                ) : (
                  <div />
                )}
              
            </s.cardNFT>
          ))}
          </s.grid>
        </div>
      </s.Container>
    </s.Screen>
  );
}
