import React, { useEffect, useState } from "react";
import * as s from "../styles/globalStyles";
import giphy from "../images/gif.gif";
import { stateStore } from "./stateStore.js";
import VIPNFTCreator from "../ABI/VIPNFTCreator";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function MintingDemo() {
  const meta = stateStore.useState((s) => s.meta);
  const web3 = stateStore.useState((s) => s.web3);
  const contractAddress = stateStore.useState((s) => s.contract);
  const priceMinting = stateStore.useState((s) => s.mintingPrice);
  const maticPrice = stateStore.useState((s) => s.maticPrice);
  const [maxMint, setMaxMint] = useState(10);
  const [number, setNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const totalPrice = 500 * number;
  const eurPrice = Math.round(parseInt(totalPrice) * maticPrice * 100) / 100;
  useEffect(() => {
    if (web3 === null || meta === null) {
      return;
    }
    handleMaxMint();
  }, []);

  const handleClick = () =>  {
    stateStore.update((s) => {
        s.NFTDemo = true;
      });
console.log("NFTDemo is true")
 }

  const handleMaxMint = async () => {
    if (web3 != null) {
      const contract = new web3.eth.Contract(VIPNFTCreator, contractAddress);
      const result = await contract.methods
        .maxMintAmount()
        .call({ from: meta });
      setMaxMint(result);
      console.log("max mint amount is " + result);
    }
  };

  const handleMint = async () => {
    if (web3 != null) {
      setIsLoading(true);
      const contract = new web3.eth.Contract(VIPNFTCreator, contractAddress);
      console.log("price to create NFT is " + priceMinting);
      console.log("contract  is " + contract);
      let MetaValue = priceMinting * number;

      await contract.methods
        .mint(`${number}`) // `${number}`number of nft to be create
        .send({
          from: meta,
          value: web3.utils.toWei(`${MetaValue}`, "ether"),
          //  value: web3.utils.toWei(`${priceMinting}`, "ether"),
          //  gas: 1000000
        });
      setIsLoading(false);
    }
  };

  return (
    <s.Container>
      <s.cardDemo>
        <s.TextDescription>
        <Trans i18nKey="moneyDemo-description1" />
    
        </s.TextDescription>
        <s.TextDescription>
        <Trans i18nKey="moneyDemo-description2" />
      
        </s.TextDescription>
        <s.TextDescription>
        <Trans i18nKey="moneyDemo-description3" />
     </s.TextDescription>
        <s.button onClick={handleClick}>         <Trans i18nKey="moneyDemo-button1" /></s.button>
      </s.cardDemo>
      
      <s.card style={{maxWidth:"350px"}}>
        <s.TextSubTitle> <Trans i18nKey="moneyDemo-button2" /></s.TextSubTitle>
        <s.card style={{ borderRadius: "2em"}}>
          <img
            src={giphy}
            style={{ width: "300px", borderRadius: "3em" }}
            alt="gif"
          />
          <s.slider>
            <input
              type="range"
              min="1"
              max={maxMint}
              step="1"
              defaultValue="1"
              onChange={(e) => setNumber(parseInt(e.target.value, 10))}
            ></input>
          </s.slider>
          <div>{number} NFT </div>
          <div>Price : {totalPrice} MATIC </div>
          <div>Price : {eurPrice} EUR</div>
          <s.button onClick={handleClick}> <Trans i18nKey="moneyDemo-button3" /></s.button>
          {isLoading ? <s.loader /> : <div />}
          <a href="https://opensea.io/collection/vipnftcreator" target="_blank">
        <s.TextDescription><Trans i18nKey="moneyDemo-description4" /></s.TextDescription>
        </a>
        </s.card>
      </s.card>
    </s.Container>
  );
}
