import React from "react";
import styled, { keyframes } from "styled-components";
import * as s from "../styles/globalStyles";
import { stateStore } from "./stateStore.js";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function Landing() {
  const { t } = useTranslation();

  const handleClick = () => {
    stateStore.update((s) => {
      s.begin = true;
    });
  };

  return (
    <div>
      <s.cardDemo>
        <s.TextSubTitle>
          {" "}
          <Trans i18nKey="landing-title" />
        </s.TextSubTitle>
        <s.TextDescription>
          <Trans i18nKey="landing-description" />
        </s.TextDescription>
        <s.TextSubTitle>
          <Trans i18nKey="landing-title2" />
        </s.TextSubTitle>
        <s.TextDescription>
          <Trans i18nKey="landing-description2" />
        </s.TextDescription>
        <s.button onClick={handleClick}>
       
          <Trans i18nKey="landing-button" />
        </s.button>
      </s.cardDemo>
    </div>
  );
}
