const NFTArrayDemo = [
  {
    "name": "VIP NFT Creator #1",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/1.png",
    "dna": "040f649c3e6ec6ed85900478e6a1c7a6d55c4090",
    "edition": 1,
    "date": 1654626449967,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "VNergrorojo"
      },
      {
        "trait_type": "Scarf",
        "value": "abCrema"
      },
      {
        "trait_type": "Necklace",
        "value": "jNegrobrillanrfalsees"
      },
      {
        "trait_type": "Mouth",
        "value": "bNude"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "h"
      },
      {
        "trait_type": "Hat",
        "value": "dBlanco"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #2",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/2.png",
    "dna": "851dcdab3b0d429082aa53aa833692409cb5a779",
    "edition": 2,
    "date": 1654626316895,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "4"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "VMorado"
      },
      {
        "trait_type": "Scarf",
        "value": "abGris"
      },
      {
        "trait_type": "Necklace",
        "value": "jPlafalsea"
      },
      {
        "trait_type": "Mouth",
        "value": "gMandarina"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "lGris"
      },
      {
        "trait_type": "Glasses",
        "value": "oRojo"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #3",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/3.png",
    "dna": "7cbf016e2d5bd759c3129175833be8faf964b0cc",
    "edition": 3,
    "date": 1654626503088,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "VNergrorojo"
      },
      {
        "trait_type": "Scarf",
        "value": "abGris"
      },
      {
        "trait_type": "Mouth",
        "value": "fRojoanaranjado"
      },
      {
        "trait_type": "Eyes",
        "value": "yCerrados"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "dBlanco"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #4",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/4.png",
    "dna": "e79362d0541bc9f50c7a02917085d274fc57be98",
    "edition": 4,
    "date": 1654626558260,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "11"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "mFucsia"
      },
      {
        "trait_type": "Mouth",
        "value": "gMandarina"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "chNAfalseural"
      },
      {
        "trait_type": "Glasses",
        "value": "falseAzul"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #5",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/5.png",
    "dna": "1762d606e91c256d0ef852b996c194ad7f0810e3",
    "edition": 5,
    "date": 1654626458869,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "11"
      },
      {
        "trait_type": "Body",
        "value": "pPlafalseeado1"
      },
      {
        "trait_type": "Dress",
        "value": "wAnimalprinfalse"
      },
      {
        "trait_type": "Scarf",
        "value": "abBlanco"
      },
      {
        "trait_type": "Necklace",
        "value": "jNegro"
      },
      {
        "trait_type": "Mouth",
        "value": "uRojomedio"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "dGris"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #6",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/6.png",
    "dna": "0951b9dc5fbfb97f6f223cf37df78a941366b62b",
    "edition": 6,
    "date": 1654626525672,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "mNegroblanco"
      },
      {
        "trait_type": "Mouth",
        "value": "aCarnenude"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "lBlanco"
      },
      {
        "trait_type": "Glasses",
        "value": "falseFucsia"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #7",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/7.png",
    "dna": "a932ef931bf28fbfff5f50679fff22664de053b0",
    "edition": 7,
    "date": 1654626369721,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "10"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "mNaranjablanco"
      },
      {
        "trait_type": "Scarf",
        "value": "abGris"
      },
      {
        "trait_type": "Necklace",
        "value": "jNegrobrillanrfalsees"
      },
      {
        "trait_type": "Mouth",
        "value": "bRosa"
      },
      {
        "trait_type": "Eyes",
        "value": "yCerrados"
      },
      {
        "trait_type": "Eyebrow",
        "value": "h"
      },
      {
        "trait_type": "Hat",
        "value": "lNegro"
      },
      {
        "trait_type": "Glasses",
        "value": "falseRosa"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #8",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/8.png",
    "dna": "b4235a6980f5e40dfb2cdf01692dd0168d3567e5",
    "edition": 8,
    "date": 1654626574338,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "11"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "wBlanco"
      },
      {
        "trait_type": "Mouth",
        "value": "uRojoinfalseenso"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "lGris"
      },
      {
        "trait_type": "Glasses",
        "value": "cCelesfalsee"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #9",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/9.png",
    "dna": "26f5ab480a12992085000ec86d2dd69f83c1dc00",
    "edition": 9,
    "date": 1654626415634,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "VNegro"
      },
      {
        "trait_type": "Scarf",
        "value": "abCrema"
      },
      {
        "trait_type": "Necklace",
        "value": "jPlafalsea"
      },
      {
        "trait_type": "Mouth",
        "value": "aNaranjarojizo"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "h"
      },
      {
        "trait_type": "Hat",
        "value": "lBlanco"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #10",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/10.png",
    "dna": "95d499644f02c04bb9e6fb8aa4ef1adee210006a",
    "edition": 10,
    "date": 1654626615810,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "11"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "mNegroblanco"
      },
      {
        "trait_type": "Mouth",
        "value": "uRojomedio"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "h"
      },
      {
        "trait_type": "Hat",
        "value": "lNegro"
      },
      {
        "trait_type": "Glasses",
        "value": "falseAmarillo"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #11",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/11.png",
    "dna": "8f3f20fb19a67180300efd571e30acdde8a64e81",
    "edition": 11,
    "date": 1654626604551,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "11"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "mFucsia"
      },
      {
        "trait_type": "Mouth",
        "value": "bRosa"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "lGris"
      },
      {
        "trait_type": "Glasses",
        "value": "cMorado"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #12",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/12.png",
    "dna": "cfc0166c77908bc2b123b675b1bdc99ebf2eaa49",
    "edition": 12,
    "date": 1654626610392,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "10"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "vVinofalseinfalseo"
      },
      {
        "trait_type": "Mouth",
        "value": "uRojoinfalseenso"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "dGris"
      },
      {
        "trait_type": "Glasses",
        "value": "falseAzul"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #13",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/13.png",
    "dna": "b0b500404baab9142ef362539d21107db9b64f94",
    "edition": 13,
    "date": 1654626599853,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "wPepasblancas"
      },
      {
        "trait_type": "Mouth",
        "value": "gRosa"
      },
      {
        "trait_type": "Eyes",
        "value": "yCerrados"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "lGris"
      },
      {
        "trait_type": "Glasses",
        "value": "falseAmarillo"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #14",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/14.png",
    "dna": "b0e7d2cb2154085ddd17228a963838ced1a6f484",
    "edition": 14,
    "date": 1654626516377,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pPlafalseeado1"
      },
      {
        "trait_type": "Dress",
        "value": "VNegro"
      },
      {
        "trait_type": "Mouth",
        "value": "uNafalseural"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "dGris"
      },
      {
        "trait_type": "Glasses",
        "value": "oVerde"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #15",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/15.png",
    "dna": "969cf9d5aeac8a12201174be27d8239c220281a7",
    "edition": 15,
    "date": 1654626351704,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "5"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "VNergrorojo"
      },
      {
        "trait_type": "Scarf",
        "value": "abCrema"
      },
      {
        "trait_type": "Necklace",
        "value": "jPlafalsea"
      },
      {
        "trait_type": "Mouth",
        "value": "uRojoinfalseenso"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "lNegro"
      },
      {
        "trait_type": "Glasses",
        "value": "falseAzul"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #16",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/16.png",
    "dna": "5da3925fac6ab68b62d8817657ecd50a5394d820",
    "edition": 16,
    "date": 1654626489942,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "10"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "VNegro"
      },
      {
        "trait_type": "Scarf",
        "value": "abBlanco"
      },
      {
        "trait_type": "Mouth",
        "value": "bRosa"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "k"
      },
      {
        "trait_type": "Hat",
        "value": "dGris"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #17",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/17.png",
    "dna": "eaa210939ff53e6c5989ac2950690778e5612a6d",
    "edition": 17,
    "date": 1654626318203,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "10"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "vAzul"
      },
      {
        "trait_type": "Scarf",
        "value": "abGris"
      },
      {
        "trait_type": "Necklace",
        "value": "jBlanco"
      },
      {
        "trait_type": "Mouth",
        "value": "aRojo"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "lGris"
      },
      {
        "trait_type": "Glasses",
        "value": "cMarron"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #18",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/18.png",
    "dna": "b057776850e76ec80307c296942daf9c7f40f98f",
    "edition": 18,
    "date": 1654626382007,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "10"
      },
      {
        "trait_type": "Body",
        "value": "pPlano"
      },
      {
        "trait_type": "Dress",
        "value": "vVinofalseinfalseo"
      },
      {
        "trait_type": "Scarf",
        "value": "abNegro"
      },
      {
        "trait_type": "Necklace",
        "value": "jOro"
      },
      {
        "trait_type": "Mouth",
        "value": "fRojooscuro"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "dNegrp"
      },
      {
        "trait_type": "Glasses",
        "value": "falseRosa"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #19",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/19.png",
    "dna": "3bf063d471c1ba40e7f39e1cc3e9111d6c7e112c",
    "edition": 19,
    "date": 1654626589190,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "4"
      },
      {
        "trait_type": "Body",
        "value": "pPlafalseeado1"
      },
      {
        "trait_type": "Dress",
        "value": "mNegrofucsia"
      },
      {
        "trait_type": "Mouth",
        "value": "uRojomedio"
      },
      {
        "trait_type": "Eyes",
        "value": "yAbierfalseos"
      },
      {
        "trait_type": "Eyebrow",
        "value": "h"
      },
      {
        "trait_type": "Hat",
        "value": "chNAfalseural"
      },
      {
        "trait_type": "Glasses",
        "value": "falseBlancas"
      }
    ],
    "compiler": "Codyseum"
  },
  {
    "name": "VIP NFT Creator #20",
    "description": "This could be yours !",
    "image": "https://codyseum.mypinata.cloud/ipfs/QmRzgL5XDEQ3wXhnDfRArRZnfmQY9b6Rezy2LzLct545L3/20.png",
    "dna": "16831698d41d95e33f41b32315307da288cf1222",
    "edition": 20,
    "date": 1654626375429,
    "creator": "Nathalie Ordonez Prieto",
    "seller_fee_basis_points": 990,
    "fee_recipient": "0xa5067552b4405089A8E7B3b1143846aE42737C73",
    "attributes": [
      {
        "trait_type": "Backgrounds",
        "value": "10"
      },
      {
        "trait_type": "Body",
        "value": "pUnicoacuarela"
      },
      {
        "trait_type": "Dress",
        "value": "mFucsia"
      },
      {
        "trait_type": "Scarf",
        "value": "abCrema"
      },
      {
        "trait_type": "Necklace",
        "value": "jPlafalsea"
      },
      {
        "trait_type": "Mouth",
        "value": "sMagenfalsea"
      },
      {
        "trait_type": "Eyes",
        "value": "yGui"
      },
      {
        "trait_type": "Eyebrow",
        "value": "y"
      },
      {
        "trait_type": "Hat",
        "value": "lGris"
      },
      {
        "trait_type": "Glasses",
        "value": "falseFucsia"
      }
    ],
    "compiler": "Codyseum"
  }
]

  export default NFTArrayDemo;