import React, { useEffect } from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import logo from "../images/logo.png";
import Web3 from "web3";
import VIPNFTCreator from "../ABI/VIPNFTCreator";
import { stateStore } from "./stateStore.js";
import chainConfig from "../config/chainConfig";
import { Link } from "react-router-dom";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";

async function checkBalance(web3, meta) {
  try {
    web3.eth.getBalance(meta).then((balanceInWei) => {
      var balanceT = web3.utils.fromWei(balanceInWei);

    

      stateStore.update((s) => {
        s.balance = parseInt(balanceT, 10);
      });
    });
  } catch (error) {
    console.log(error);
  }
}


async function checkPrice(web3, meta, contractAddress) {
  const contract = new web3.eth.Contract(VIPNFTCreator, contractAddress);
  const resultMintingPrice = await contract.methods.cost().call({ from: meta });
 // console.log("resultMintingPrice",resultMintingPrice)
  stateStore.update((s) => {
    s.mintingPrice = parseInt(resultMintingPrice, 10);
  });
}

async function checkWalletOwner(web3, meta, contractAddress){
  const contract = new web3.eth.Contract(VIPNFTCreator, contractAddress);
const resultWalletOwner = await contract.methods
.walletOfOwner(meta)
.call({ from: meta });
//  setWalletOwner(resultWalletOwner);
stateStore.update((s) => {
s.walletOwner = resultWalletOwner;
});
}

const getMaticPrice=async()=>{
  fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=eur"
  ).then((res) => res.json())
  .then(
    (result) => {
      stateStore.update((s) => {
        s.maticPrice = result["matic-network"].eur;
      });

    });
    
} 
export default function Layout({ children }) {
  const web3 = stateStore.useState((s) => s.web3);
  const meta = stateStore.useState((s) => s.meta);
  const contractAddress = stateStore.useState((s) => s.contract);
  const balance = stateStore.useState((s) => s.balance);
  const maticPrice = stateStore.useState((s)=>s.maticPrice);
  const walletOwner = stateStore.useState((s) => s.walletOwner);
  let userAccount;

  async function connect() {
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const web3T = await new Web3(window.ethereum);
      stateStore.update((s) => {
        s.web3 = web3T;
      });

      try {
        // Request account access if needed
        // await window.ethereum.request({ method: 'eth_requestAccounts' });
        userAccount = (await web3T.eth.getAccounts())[0];
        stateStore.update((s) => {
          s.meta = userAccount;
        });
      } catch (error) {
        // User denied account access...
      }
    }

    //  await window.ethereum
    //     .request(
    //       chainInfo
    //       // {
    //       // method: 'wallet_addEthereumChain',
    //       // params: [{
    //       // chainId: '0x38',
    //       // chainName: 'Binance Smart Chain',
    //       // nativeCurrency: {
    //       //     name: 'Binance Coin',
    //       //     symbol: 'BNB',
    //       //     decimals: 18
    //       // },
    //       // rpcUrls: ['https://bsc-dataseed.binance.org/'],
    //       // blockExplorerUrls: ['https://bscscan.com']
    //       // }]
    //       // }
    //     )
    //     .catch((error) => {
    //       console.log(error);
    //     });

   // change network
   
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      // params: [{ chainId: Contracts.matic.chainID }], // chainId must be in hexadecimal numbers
      params : [{ chainId : "0x89"}]
    });

  
  }



  useEffect(() => {
    connect();
    getMaticPrice();
  }, []);

  useEffect(() => {
    if (web3 === null || meta === null) {
      return;
    }
    checkBalance(web3, meta);
    checkPrice(web3, meta, contractAddress);
    checkWalletOwner(web3, meta, contractAddress);
    const interval = setInterval(() => {
      checkBalance(web3, meta);
      checkWalletOwner(web3, meta, contractAddress);
     
      checkPrice(web3, meta, contractAddress);
    }, 5000);

    return () => clearInterval(interval);
  }, [web3, meta]);

  const handleConnect = async () => {
    await connect();
  };

  return (
    <s.Screen>
      <s.appBar>
        <div style={{ flexGrow: 1 }}>
          <a href="https://codyseum.com" target="blank" norel>
          <img style={{ height: "40px", marginLeft : "5px", marginTop: "5px" }} src={logo} alt="logo" />
          </a>
        </div>
        <LanguageSelect style={{marginTop : "8px"}}/>
        {walletOwner ? (<Link to={"/wallet"}><s.button >My NFTs</s.button></Link>) : <div/>}
        {meta ? (
          <s.button secondary><Trans i18nKey="layout-connected" /></s.button>
        ) : (<div>
          <s.buttonC className="connectComputer" onClick={handleConnect}><Trans i18nKey="layout-connect" /></s.buttonC>
          <a href="https://metamask.app.link/dapp/vipnftc-reator-aimwlrs0t-platiniumdefi.vercel.app/" target="_blank" norel>
          <s.buttonM mobile onClick={handleConnect}><Trans i18nKey="layout-connect" /></s.buttonM>
          </a>
          </div>
        )}
      </s.appBar>
      {children}

      <s.footer >
          
        <a
          href="https://www.linkedin.com/in/s%C3%A9bastien-valla-1ba667a0/"
          target="_blank"
        >Made by Sebastien, all rights reserved 2022</a>
      </s.footer>
      </s.Screen>
  );
}
