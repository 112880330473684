import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { ArrowDropDown } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import * as s from "../styles/globalStyles";
import styled from "styled-components";


const languageMap = {
  en: { label: "EN", dir: "ltr", active: true },
  es: { label: "ES", dir: "ltr", active: false },
  fr: { label: "FR", dir: "ltr", active: false }
};

const LanguageSelect = () => {

 const selected = i18next.resolvedLanguage
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <div className="{d-flex justify-content-end align-items-center language-select-root}">
      <Button style={{marginTop :"6px"}} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
   
          <s.TextDescription>
        {languageMap[selected].label}
        </s.TextDescription>
      
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
   
          <s.TextDescription style={{color: "var(--secondary)"}}>
          <List>
           {Object.keys(languageMap)?.map(item => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
          </s.TextDescription>
      
      </Popover>
    </div>
  );
};

export default LanguageSelect;