import Layout from "./components/Layout";
import Home from "./components/Home.js";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import CheckNFT from "./components/CheckNFT";
import Winner from "./components/Winner";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wallet" element={<CheckNFT/>}/>
          <Route path="winner" element={<Winner/>}/>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
