import React from "react";
import styled, { keyframes } from "styled-components";
import * as s from "../styles/globalStyles";
import { stateStore } from "./stateStore.js";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function Metamask() {
  const handleClick = () => {
    stateStore.update((s) => {
      s.metaPass = true;
    });
    console.log("metapass is true");
  };

  return (
    <div>
      <s.cardDemo>
        <s.TextDescription>
          <Trans i18nKey="metamask-description" />
        </s.TextDescription>
        <s.TextDescription>
          <Trans i18nKey="metamask-description2" />
        </s.TextDescription>
        <s.button onClick={handleClick}>
          <Trans i18nKey="metamask-button1" />
        </s.button>
      </s.cardDemo>

      <s.card>
        <s.TextSubTitle>
          {" "}
          <Trans i18nKey="metamask-description3" />
        </s.TextSubTitle>
        <s.TextDescription>
          <Trans i18nKey="metamask-description4" />
        </s.TextDescription>
        <s.TextDescription>
          <Trans i18nKey="metamask-description5" />
        </s.TextDescription>
        <a
          href="https://metamask.io/download/"
          target="_blank"
          rel="noreferrer"
        >
          <s.button>
            <Trans i18nKey="metamask-button2" />
          </s.button>
        </a>
      </s.card>
    </div>
  );
}
