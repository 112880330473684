import React, { useEffect, useState } from "react";
import * as s from "../styles/globalStyles";
import { stateStore } from "./stateStore.js";
import VIPNFTCreator from "../ABI/VIPNFTCreator";
import { Link } from "react-router-dom";
import NFTArrayDemo from "../config/NFTArrayDemo";
import rarity from "../config/rarity";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function CheckNFTDemo() {
  const meta = stateStore.useState((s) => s.meta);
  const web3 = stateStore.useState((s) => s.web3);
  const contractAddress = stateStore.useState((s) => s.contract);
  const walletOwner = stateStore.useState((s) => s.walletOwner);
  // const [walletOwner, setWalletOwner] = useState(0);
  const [NFTDetail, setNFTDetail] = useState(0);
  const [NFTmetadata, setNFTmetadata] = useState([]);

  const handleClickHome = () => {
    stateStore.update((s) => {
      s.metaPass = false;
      s.moneyPass = false;
      s.mintingPass = false;
      s.NFTDemo = false;
    });
  };
  

  return (
    <s.Screen>
      <s.Container>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <s.button onClick={handleClick}>Refresh</s.button> */}

          <s.button onClick={handleClickHome}><Trans i18nKey="checknft-button1" /></s.button>
        </div>

        <s.card>
          <s.TextDescription> <Trans i18nKey="checknft-description1" />
      
          </s.TextDescription>
        </s.card>
        <div style={{ display: "flex", flexDirection: "raw" }}>
          <s.grid>
            {NFTArrayDemo.map((NFTArray) => (
              // <s.cardNFT key={NFTArray.name}>
              <s.cardNFT key={NFTArray.name} >
                <img
                  style={{ maxWidth: "90%", maxHeight: "90%", padding: "10px" }}
                  src={NFTArray.image}
                />
                {NFTArray.name}
                {NFTArray.attributes[2].value == rarity.value ? (
                  <Link to="/winner">
                  <s.button style={{width : "auto", padding : "8px"}}><Trans i18nKey="checknft-button2" /></s.button>
                  </Link>
                ) : (
                  <div />
                )}
              </s.cardNFT>
            ))}
          </s.grid>
        </div>
      </s.Container>
    </s.Screen>
  );
}
