import styled, { css, keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const entry = keyframes`
from {
  transform : scale(0.8);
   opacity : 0;
}
to {transform : scale(1);
  opacity :1;}

`;

const shining = keyframes`

  from {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(0, 17, 255), 0 0 40px #0ff, 0 0 50px hsl(189, 100%, 45%), 0 0 60px hsl(189, 100%, 45%), 0 0 70px hsl(189, 100%, 45%);
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px #dd8f1a, 0 0 40px #dd8f1a, 0 0 50px #dd8f1a 0 0 60px #dd8f1a, 0 0 70px #dd8f1a, 0 0 80px #dd8f1a;
  }
`;

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.h1`
  color: var(--accent-text);
  font-size: 4em;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 1em;
  justify-self: ;
`;

export const TextSubTitle = styled.h2`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 1.2em;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const appBar = styled.div`
  width: 100%;
  height: 50px;
  background-color: var(--secondary);
  flex-direction: row;
  display: flex;
`;

export const button = styled.button`
  display: inline-block;
  border-radius: 8px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 120px;
  background: var(--third);
  color: var(--primary-text);
  border: 2px solid white;
  cursor: pointer;
  :hover {
    //  box-shadow: 2px 2px 2px 1px grey;
    background: var(--primary);
  }

  ${(props) =>
    props.secondary &&
    css`
      background: var(--primary);
      color: var(--primary-text);
    `}
`;

export const buttonM = styled.button`
  display: inline-block;
  border-radius: 8px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 120px;
  background: var(--third);
  color: var(--primary-text);
  border: 2px solid white;
  cursor: pointer;
  @media (min-width: 500px) {
    display: none;
  }
  :hover {
    //  box-shadow: 2px 2px 2px 1px grey;
    background: var(--third);
  }
`;

export const buttonC = styled.button`
  display: inline-block;
  border-radius: 8px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 130px;
  background: var(--third);
  color: var(--primary-text);
  border: 2px solid white;
  cursor: pointer;
  @media (max-width: 500px) {
    display: none;
  }
  :hover {
    //  box-shadow: 2px 2px 2px 1px grey;
    background: var(--third);
  }
`;

export const footer = styled.footer`
  display: flex;
  flex: 1;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.5;
  color: var(--primary-text);
`;

export const card = styled.div`
  background-color: var(--secondary);
  min-width: 300px;
  max-width: 80vw;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4em;
  flex-direction: column;
  padding: 1em;
  margin-top: 16px;
  text-align: center fit-content(50%);
  animation: ${entry} 0.5s linear;
`;

export const cardNFT = styled.div`
  background-color: var(--secondary);
  width: 250px;
  height: auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4em;
  flex-direction: column;
  padding: 1em;
  text-align: center;
  margin: 10px;
  animation: ${entry} 1s linear;
`;

export const cardDemo = styled.div`
  background-color: var(--secondary);
  min-width: 300px;
  max-width: 80vw;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4em;
  flex-direction: column;
  padding: 2em;
  margin-top: 16px;
  text-align: center fit-content(80%);
  animation: ${entry} 0.2s linear;
  animation : ${shining}  5s ease-in-out infinite alternate;
`;

export const slider = styled.div`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  margin-top: 10px;
  margin-left: 20%;
  margin-right: auto;
  display: flex;
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
`;

export const grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  grid-auto-rows: minmax(auto, auto);
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const loader = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid var(--third);
  width: 30px;
  height: 30px;
  animation: ${rotate} 1s linear infinite;
`;

export const formu = styled.form`
  margin: 16px;
  display: flex;
  flex-direction: column;
`;
