import React, { useEffect } from "react";
import * as s from "../styles/globalStyles";
import { stateStore } from "./stateStore.js";
import Prices from "../config/prices";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function Money() {
  const meta = stateStore.useState((s) => s.meta);
  const web3 = stateStore.useState((s) => s.web3);
  const balance = stateStore.useState((s) => s.balance);
  const priceMinting = stateStore.useState((s) => s.mintingPrice);

  const handleClick = () => {
    stateStore.update((s) => {
      s.moneyPass = true;
    });

    console.log("moneyPass is true");
  };

  return (
    <div>
      <s.cardDemo>
        <s.TextDescription>
        <Trans i18nKey="money-description1" />
         
        </s.TextDescription>
        <s.TextDescription>
        <Trans i18nKey="money-description2" />
         
        </s.TextDescription>
        <s.button onClick={handleClick}>   <Trans i18nKey="money-button1" /></s.button>
      </s.cardDemo>
      <s.card>
        <s.TextSubTitle>
        <Trans i18nKey="money-description3" />
          {balance}
          <Trans i18nKey="money-description4" />
          {priceMinting} 
          <Trans i18nKey="money-description5" />
        </s.TextSubTitle>
      </s.card>
      <s.card
        style={{
          marginTop: "10px",
          backgroundColor: "var(--primary)",
          borderRadius: "20px",
        }}
      >
        <iframe
          src="https://widget.onramper.com?color=266677&defaultCrypto=MATIC&apiKey=pk_prod_wu3_3APiPSgZ3LYOEbaAegOJxlkHvB43oH_c_53x0Bw0"
          height="600px"
          width="300px"
          title="Onramper widget"
          frameborder="0"
          allow="accelerometer;
  autoplay; camera; gyroscope; payment"
          darkMode
          style={{ boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)" }}
        >
          <a
            href="https://widget.onramper.com"
            target="_blank"
            rel="noreferrer"
          >
            Buy crypto
          </a>
        </iframe>
      </s.card>
    </div>
  );
}
