import React, { useState } from "react";
import winnerGif from "../images/winner.gif";
import * as s from "../styles/globalStyles";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";

export default function Winner() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [msgSent, setMsgSent] = useState(false);
    const message = name + "a gagné une rencontre avec toi, recontacte le/la au " + tel + " ou sur son email " + email ;

  const handleSubmit = () => {
      
setMsgSent(true);
  };
  return (
    <s.Container>
      <s.TextTitle style={{fontSize :"2.5em"}}><Trans i18nKey="winner-title" /></s.TextTitle>
      <s.TextSubTitle>
      <Trans i18nKey="winner-subtitle" />
      
      </s.TextSubTitle>
      <img src={winnerGif} alt="winner" style={{ margin: "16px", overflow : "hidden", maxWidth : "100%" }}></img>



{!msgSent ? <s.Container>
      <s.TextDescription style={{padding : "8px"}}>
      {" "}
      <Trans i18nKey="winner-description1" />
      
    </s.TextDescription>
   
      <s.card>
      <s.formu noValidate autoComplete="off" onSubmit={handleSubmit}>
        <label style={{marginBottom : "8px"}}> <Trans i18nKey="winner-labelName" /></label> {" "}
        <input type="text" style={{marginBottom : "8px"}}  onChange={(e) => setName(e.target.value)}/>
        <label style={{marginBottom : "8px"}}><Trans i18nKey="winner-labelPhone" /></label> {" "}
        <input type="text" style={{marginBottom : "8px"}}   onChange={(e) => setTel(e.target.value)}/>
        <label style={{marginBottom : "8px"}}><Trans i18nKey="winner-labelMail" /></label> {" "}
        <input type="text" style={{marginBottom : "8px"}}  onChange={(e) => setEmail(e.target.value)}/>
        <s.button type="submit"><Trans i18nKey="winner-button1" /></s.button>
      </s.formu>
      </s.card>
     
      </s.Container>
:
<div>
<s.cardDemo style={{marginTop : "8px"}}>
<s.TextDescription>
       
        <Trans i18nKey="winner-description2" />
      
      </s.TextDescription>
      </s.cardDemo>
</div>

}


    </s.Container>
  );
}
